import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles ((theme) => ({
    button: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        minHeight: 54,
        padding: '0 1.6rem',
        border: '1px solid #edf1f2',
        color: '#434c53',
        fontSize: '1.125rem',
        textDecoration: 'none',
        backgroundColor: '#edf1f2',
        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
        '&:hover, &:focus': {
            backgroundColor: 'transparent',
            borderLeftColor: 'transparent',
            borderTopColor: '#434c53',
            borderRightColor: 'transparent',
            borderBottomColor: '#434c53',
        }
    },
}));

/**
 * Component for link styled as button.
 */
export default function (props) {
    const {className, label, link, target} = props;

    const classes = useStyles ();

    return (
        <a className={clsx (className, classes.button)} href={link} target={target}>{label}</a>
    );
}
