/**
 * Convert raw string data from qrcode into IQRCodeData.
 */
export function ConvertRawIntoQRCodeData (data) {
    const lines = data.split ('/');

    return {
        rawData: data,
        orderNum: lines[1],
        windowNum: lines[2]
    };
}
