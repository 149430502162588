import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import ButtonLink from "./ButtonLink";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > button": {
      "margin-right": theme.spacing(2),
    },
  },
  container: {
    marginBottom: theme.spacing (2)
  }
}));

const Contact = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.container} container>
      <Grid item xs={12} className={classes.root}>
        <Typography variant="h3">{t("contact.title")}</Typography>

        <ButtonLink label={t ('contact.button')} link='https://www.storen.ch/de/kontakt/' target='_blank'/>
      </Grid>
    </Grid>
  );
};

export default Contact;
