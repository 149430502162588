import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#002d72",
      dark: "#001f4f",
      light: "#33578e",
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    h1: {
      marginBottom: 24,
      color: '#002d72',
      fontSize: '2.5rem',
      fontWeight: 400,
    },
    h2: {
      marginBottom: 24,
      color: '#002d72',
      fontSize: '2.5rem',
      fontWeight: 400
    },
    h3: {
      marginBottom: 24,
      color: '#002d72',
      fontSize: '2.25rem',
      fontWeight: 400
    },
    button: {
      fontWeight: 400,
    },
  },
});

export default theme;
