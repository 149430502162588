import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  footer: {
    position: "fixed",
    "text-align": "center",
    bottom: "0",
    width: "100vw",
    paddingTop: 16,
    paddingBottom: 16,
    color: 'white',
    backgroundColor: '#434c53'
  },
  item: {
    display: 'inline-block',
    padding: '0px 12px',
    color: 'white',
    fontSize: 18,
    fontWeight: 300,
    fontFamily: 'Aeonik-Light',
    textDecoration: 'none'
  },
  divider: {
    display: 'inline-block',
    fontSize: 18,
    fontWeight: 400,
    fontFamily: 'Aeonik',
  }
}));

export default function TheFooter() {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <span className={classes.item}>© Schenker Storen</span> 
      
      <div className={classes.divider}>|</div>

      <a className={classes.item} href="https://www.storen.ch" target="_blank">www.storen.ch</a>
    </div>
  );
}
