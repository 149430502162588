import React from "react";
import TheAppBar from "./TheAppBar";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DetailView from "../views/DetailView";
import TheFooter from "./TheFooter";
import MainView from "../views/MainView";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles ((theme) => ({
  main: {
    paddingBottom: 54
  }
}));

const MainContent = () => {
  const classes = useStyles ();

  return (
    <React.Fragment>
      <TheAppBar />

      <Container className={classes.main} fixed>
        <Router>
          <Switch>
            <Route path="/:code">
              <DetailView />
            </Route>

            <Route path="/">
              <MainView/>
            </Route>
          </Switch>
        </Router>
      </Container>

      <TheFooter />
    </React.Fragment>
  );
};

export default MainContent;
