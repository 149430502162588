import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles ((theme) => ({
    description: {
        textAlign: 'center'
    }
}));

export default function MainView () {
    const classes = useStyles ();

    const {t} = useTranslation ();

    return (
        <p className={classes.description}>{t ('main.description')}</p>
    );
}
