import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Logo from "../assets/logo.svg";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Globe from '../assets/globe.svg';
import Close from '../assets/close.svg';
import Button from './Button';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: 80,
  },
  bar: {
    position: 'absolute',
    height: 25,
    left: 0,
    right: 0,
    zIndex: -1,
    backgroundColor: '#edf1f2'
  },
  button: {
    color: "white",
  },
  logoContainer: {
    position: 'relative'
  },
  logo: {
    display: 'block',
    position: 'absolute',
    width: 99,
    height: 50,
    left: 0,
  },
  languages: {
    display: 'block',
    position: 'absolute',
    width: 36,
    height: 36,
    right: -6,
    top: 24,
    padding: 0,
    border: 'none',
    margin: 0,
    background: 'transparent',
  },
  globe: {
    width: 24,
    height: 24,
  },
  menu: {
    position: 'fixed',
    top: 80,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'white',
  },
  list: {
    listStyle: 'none',
    padding: '0 16px 0 16px',
    margin: 0,
  }
}));

const AppBarButton = (props) => {
  const classes = useStyles();
  return <Button {...props} className={classes.button} />;
};

const AuthButtons = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const { t } = useTranslation();

  if (isAuthenticated) {
    return (
      <AppBarButton variant="text" onClick={() => instance.logoutRedirect()}>
        {t("auth.logOut")}
      </AppBarButton>
    );
  } else {
    return (
      <AppBarButton variant="text" onClick={() => instance.loginRedirect()}>
        {t("auth.logIn")}
      </AppBarButton>
    );
  }
};

export default function TheAppBar() {
  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const [menu, setMenu] = useState (false);

  /**
   * Change language to different language.
   */
  const ChangeLanguage = (lang) => {
    i18n.changeLanguage (lang);

    setMenu (false);
  };

  const menuJSX = menu ? (
    <div className={classes.menu}>
        <ul className={classes.list}>
          <li>
            <Button label={t ('change_language.english')} list listFirst onClick={() => ChangeLanguage ('en')}/>
          </li>

          <li>
            <Button label={t ('change_language.french')} list onClick={() => ChangeLanguage ('fr')}/>
          </li>

          <li>
            <Button label={t ('change_language.german')} list onClick={() => ChangeLanguage ('de')}/>
          </li>
          
          <li>
            <Button label={t ('change_language.italian')} list onClick={() => ChangeLanguage ('it')}/>
          </li>
        </ul>
    </div>
  ) : null;

  return (
    <>
      <div className={classes.container}>
        <div className={classes.bar}/>

        <Container fixed>
          <div className={classes.logoContainer}>
            <a href="/">
              <img className={classes.logo} src={Logo} alt="Schenker Storen logo" width={154} />
            </a>
            
            <button className={classes.languages} type="button" onClick={() => setMenu (!menu)}>
              <img className={classes.globe} src={menu ? Close : Globe} alt="Language selector"/>
            </button>
          </div>
        </Container>
      </div>

      {menuJSX}
    </>
  );
}
