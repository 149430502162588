import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles ((theme) => ({
  container: {
    marginBottom: theme.spacing (2)
  },
  item: {
    marginBottom: theme.spacing (1)
  },
  label: {
    display: 'inline-block',
    paddingRight: theme.spacing (1),
    color: '#002d72'
  }
}));

const Detail = (props) => {
  const { qrCodeData, fetchedData } = props;

  const {t} = useTranslation ();

  const classes = useStyles ();

  return (
    <div className={classes.container}>
      <Typography variant="h1">{t ('detail.title')}</Typography>

      <Item label={t ('detail.orderNum.label')} value={qrCodeData?.orderNum}/>

      <Item label={t ('detail.windowNum.label')} value={qrCodeData?.windowNum}/>

      <Item label={t ('detail.itemName.label')} value={fetchedData?.itemName}/>
    </div>
  );
};

/**
 * Component for data info.
 */
function Item (props) {
  const {label, value} = props;

  const classes = useStyles ();

  return (
    <div className={classes.item}>
      <span className={classes.label}>{label}</span>

      <span>{value}</span>
    </div>
  );
}

export default Detail;
