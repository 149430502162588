import Report from "../components/Report";
import Contact from "../components/Contact";
import { useEffect, useState } from "react";
import { ConvertRawIntoQRCodeData } from "../model/QRCodeData";
import { BeError, GetPromise, kConfig } from "../core/Api";
import { LinearProgress, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Detail from "../components/Detail";

const useStyles = makeStyles ((theme) => ({
  invalid: {
    color: 'red',
    textAlign: 'center'
  }
}));

const DetailView = () => {
  // const params = useParams ();
  // const {code} = params;
  const code = window.location.pathname;

  const classes = useStyles ();

  const {t} = useTranslation ();

  const [loaded, setLoaded] = useState (false);
  const [validCode, setValidCode] = useState (false);
  const [qrCodeData, setQrCodeData] = useState ();
  const [fetchedData, setFetchedData] = useState ();

  /**
   * Load data from BE for QRCode data.
   */
  const LoadData = async (data) => {
    const result = await GetPromise (`${kConfig.version}/itemInfo?orderNum=${data.orderNum}&windowNum=${data.windowNum}`);

    setLoaded (true);

    if (result.error) {
      setValidCode (false);

      setFetchedData (result);
    } else {
      setValidCode (true);

      setFetchedData (result.result);
    }
  };

  useEffect (() => {
    setLoaded (false);

    if (code) {
      const data = ConvertRawIntoQRCodeData (code);

      setQrCodeData (data);
      
      LoadData (data);
    } else {
      setLoaded (true);

      setValidCode (false);
    }
  }, [code]);

  if (!loaded) {
    return (
      <LinearProgress/>
    );
  }

  if (!validCode) {
    return (
      <p className={classes.invalid}>{BeError (fetchedData, t, t ('qrcode.invalid'))}</p>
    );
  }

  return (
    <>
      <Detail qrCodeData={qrCodeData} fetchedData={fetchedData}/>

      <Report />

      <Contact />
    </>
  );
};

export default DetailView;
