import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const useStyles = makeStyles ((theme) => ({
    button: {
        display: 'inline-block',
        padding: '.9375rem 1.6rem',
        border: '1px solid #edf1f2',
        color: '#434c53',
        fontSize: '1.125rem',
        textDecoration: 'none',
        backgroundColor: '#edf1f2',
        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
        '&:hover, &:focus': {
            backgroundColor: 'transparent',
            borderLeftColor: 'transparent',
            borderTopColor: '#434c53',
            borderRightColor: 'transparent',
            borderBottomColor: '#434c53',
        }
    },
    list: {
        width: '100%',
        padding: '0.625rem 0',
        background: 'transparent',
        textAlign: 'left',
        borderColor: 'transparent',
        borderBottomColor: '#434c53',
        '&:hover, &:focus': {
            backgroundColor: 'transparent',
            borderLeftColor: 'transparent',
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
            borderBottomColor: '#434c53',
            backgroundColor: '#edf1f2',
        }
    },
    listFirst: {
        borderTopColor: '#434c53',
        '&:hover, &:focus': {
            borderLeftColor: 'transparent',
            borderTopColor: '#434c53',
            borderRightColor: 'transparent',
            borderBottomColor: '#434c53',
        }
    }
}));

/**
 * Component for styled button.
 */
export default function (props) {
    const {className, label, onClick, list, listFirst} = props;

    const classes = useStyles ();

    return (
        <button type="button" className={clsx (className, classes.button, list ? classes.list : null, listFirst ? classes.listFirst : null)} onClick={onClick}>{label}</button>
    );
}
