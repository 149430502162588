import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import ButtonLink from "./ButtonLink";
import { Call } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > button": {
      "margin-right": theme.spacing(2),
    },
  },
  item: {
    marginRight: theme.spacing (2),
    marginBottom: theme.spacing (2)
  },
  itemLast: {
    marginRight: 0
  }
}));

const Report = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.root}>
        <Typography variant="h3">{t("report.title")}</Typography>
        
        <ButtonLink className={classes.item} label={t ('report.button.form')} link={t ('report.button.link')} target="_blank"/>

        <ButtonLink className={clsx (classes.item, classes.itemLast)} label={<Call/>} link="tel:0800202202"/>
      </Grid>
    </Grid>
  );
};

export default Report;
