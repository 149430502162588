import axios from "axios";

const apiHost = process.env.REACT_APP_API_URL
export const kConfig = {
    // Dev
    // host: kDevHost,
    // Prod
    host: apiHost,
    version: '/v1'
};

/**
 * Send GET request to the API, use for Promise.
 */
export async function GetPromise (endpoint, cancelTokenSource) {
	const url = `${kConfig.host}${endpoint}`;
	const params = {
		headers: {
            'Content-Type': 'application/json',
        },
	};

	params.cancelToken = cancelTokenSource?.token;
	
	const result = await new Promise ((resolve, reject) => {
        axios.get (url, params)
		.then (response => {
			const data = response.data;

			if (data.error) {
				resolve ({
					result: data,
					error: true
				});
			} else {
				resolve ({
					result: data
				});
			}
		})
		.catch (error => {
			console.error (error);

			resolve ({
				result: error.response.data,
                error: true
            });
		});
    });

	return result;
}

/**
 * Process BE error by code and return correct message.
 */
export function BeError (result, t, generalErrorMsg) {
	if (result && result.error && result.result) {
		const data = result.result;

		let message = t (data.errorCode);

		if (data.errorParams) {
			for (const index in data.errorParams) {
				message = message.replace (`{${index}}`, data.errorParams[index]);
			}
		}

		const theMessage = message == data.errorCode ? data.error : message;

		if (theMessage) {
			return theMessage;
		}
	}

	return generalErrorMsg;
}
